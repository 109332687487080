import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="pdf-reader"
export default class extends Controller {
  static targets = ["iframe", "pageNumber", "textarea"]
  static values = {
    bookId: String,
    bookTitle: String,
    pageNumber: Number
  }

  async connect() {
    this.pageNumberTarget.value = this.pageNumberValue

    setTimeout(() => {
      this.iframeTarget.contentWindow.PDFViewerApplication.eventBus._on("pagechanging", event => {
        this.pageNumberTarget.value = event.pageNumber
        get(this.#contentUrl(), { responseKind: "turbo-stream" })
      })
    }, 1000)
  }

  firstPage() {
    this.iframeTarget.contentWindow.PDFViewerApplication.page = 1
  }

  prevPage() {
    if (this.textareaTarget === document.activeElement) {
      return
    }

    this.iframeTarget.contentWindow.PDFViewerApplication.pdfViewer.previousPage()
  }

  nextPage() {
    if (this.textareaTarget === document.activeElement) {
      return
    }

    this.iframeTarget.contentWindow.PDFViewerApplication.pdfViewer.nextPage()
  }

  lastPage() {
    this.iframeTarget.contentWindow.PDFViewerApplication.page = this.iframeTarget.contentWindow.PDFViewerApplication.pdfViewer.pagesCount
  }

  changePage() {
    let requestedPage = Number(this.pageNumberTarget.value)

    if (requestedPage > 0 && requestedPage <= this.iframeTarget.contentWindow.PDFViewerApplication.pdfViewer.pagesCount) {
      this.iframeTarget.contentWindow.PDFViewerApplication.page = requestedPage
    } else {
      alert(`Invalid page number (Max: ${this.iframeTarget.contentWindow.PDFViewerApplication.pdfViewer.pagesCount})`)
      this.pageNumberTarget.value = this.iframeTarget.contentWindow.PDFViewerApplication.page
    }
  }

  downloadCurrentPage() {
    const link = document.createElement("a")
    link.href = this.#currentPageView().canvas.toDataURL("image/png")
    link.download = `${this.bookTitleValue}-${this.iframeTarget.contentWindow.PDFViewerApplication.page}.png`
    link.click()
  }

  async copyCurrentPageToClipboard() {
    this.#currentPageView().canvas.toBlob(async (blob) => {
      const item = new ClipboardItem({ "image/png": blob })
      await navigator.clipboard.write([item])
    }, "image/png")
  }

  #contentUrl() {
    return `/books/${this.bookIdValue}/pages/${this.iframeTarget.contentWindow.PDFViewerApplication.page}/edit`
  }

  #currentPageView() {
    return this.iframeTarget.contentWindow.PDFViewerApplication.pdfViewer.getPageView(this.iframeTarget.contentWindow.PDFViewerApplication.page - 1)
  }
}
